<template lang="pug">
div(v-click-outside="hideLiveSearch" @keydown.esc="hideLiveSearch").live-search
  slot(
    :value="value"
    :onType="onType"
    :onFocusToggle="onFocus"
    :change="change"
    :hideLiveSearch="hideLiveSearch"
  )
    search(
      data-test="candidate-search"
      :value="value"
      @input="onType"
      @onFocusToggle="onFocus"
      @keypress-esc="hideLiveSearch"
      @enter-key-pressed="$emit('enter-key-pressed', $event, hideLiveSearch)"
      placeholder='Search by CBR account, client name, data etc…'
    )
  div(data-test="search-live" v-if="showing").live-search__wrapper
    v-progress-linear(v-if="loading" data-test="search-live-loading" :indeterminate="true")
    div(data-test="search-live-clear" v-show="isClearList").live-search__clear No results were found for your search
    slot(name="live-search" :select="select")
      live-search-item(v-for="item in list" :key="item.id" :item="item")
</template>

<script>
import Search from '@/components/global/Search.vue'
import LiveSearchItem from '@/components/global/LiveSearch/LiveSearchItem.vue'
import showingMixin from '@/mixins/showing.mixin'
import vClickOutside from 'v-click-outside'
import _ from 'lodash'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },

  mixins: [showingMixin],

  props: {
    value: {
      required: true,
    },
    list: Array,
    dataTest: String,
    loading: Boolean,
    placeholder: String
  },

  computed: {
    isClearList() {
      return !this.list || !this.list.length
    }
  },

  methods: {
    onType: _.debounce(function (val) {
      this._onType(val)
    }, 800),

    _onType(val) {
      this.$emit('input', val)
      this.liveSearchToggle(val)
    },

    liveSearchToggle(val) {
      if (val && val.length > 2) {
        this.startLiveSearch(val)
        this.updateShowing(true)
      } else this.updateShowing(false)
    },

    startLiveSearch(val) {
      this.$emit('searching', val)
    },

    onFocus(isFocused) {
      if (isFocused && this.value) this.liveSearchToggle(this.value)
      if (isFocused) this.$emit('onFocusToggle', isFocused)
    },

    hideLiveSearch() {
      this.showing = false
      this.$emit('onFocusToggle', false)
    },

    select(val) {
      this.$emit('input', val)
      this.$emit('select', val)
      this.hideLiveSearch()
    },

    change(val) {
      this.$emit('change', val)
    }
  },

  components: {
    search: Search,
    LiveSearchItem
  }
}
</script>

<style lang="scss">
.live-search {
  position: relative;
  margin-left: 20px;

  &__wrapper {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    display: table;
    overflow-y: auto;
    max-height: 240px;
    width: 100%;
    border: 1px solid $border-color;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #FFFFFF;
    box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.15);
  }

  &__clear {
    padding-bottom: 30px;
    padding-left: 16px;
    font-size: 14px;
  }
}
</style>
